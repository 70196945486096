import React from 'react'
import './header.css'
import CTA from './CTA'

import ME from '../../assets/ME.png'
import HeaderSocials from './HeaderSocials'
//import {FaLinkedin} from 'react-icons/bs'



const header = () => {
  return (
    <header>
     <div className= "container header_container" >
        <h5>Hello I'm</h5>
        <h1>Rupesh Shrestha</h1>
        <h5 className='text-light'>Full Stack Developer</h5>
         <CTA />
         <HeaderSocials />


        
        
         <div className='ME'>
          <img src={ME} alt="me"  />
         </div>


         <a href="#contact" className='scroll_down'>Scroll Down </a>
      
     </div>
    </header>
  )
}

export default header
